<template>
  <div>

    <EmailEditor
      :appearance="appearance"
      :min-height="minHeight"
      :project-id="projectId"
      :locale="locale"
      :options="options"
      v-on:load="editorLoaded"
      ref="emailEditor"
      v-if="!delete_editor"
      />
  </div>
</template>

<script>
import { EmailEditor } from 'vue-email-editor';
import __tags from '@/assets/plugins/email-editor/merge-tags.js';
import { mapGetters } from 'vuex';

export default {
  name: 'EmailTemplateEditor',
  components: {
    EmailEditor
  },
  props: ['design'],
  emits: ['saveDesign', 'plaintext'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    options() {
      return {
        user: {
          id: this.currentCompanyId
        },
        mergeTags: {},
        features: {
          userUploads: true
        }
      }
    },
  },
  data() {
    return {
      
      delete_editor: false,
      minHeight: "790px",
      locale: localStorage.getItem("language") || "sv",
      projectId: 14545,
      appearance: {
        theme: 'light',
        panels: {
          tools: {
            dock: 'right'
          }
        }
      },
    }
  },
  mounted() {
    
    __tags.filter(item => {
      this.options.mergeTags[item.id] = { name: item.name, value: `%${item.id}%` };
    });
  },
  methods: {
    force_reload() {
      this.loaded = false;
      this.delete_editor = true;

      this.$nextTick(()=>{
        this.delete_editor = false;
      });
    },
    editorLoaded() {

      // Pass your template JSON here
      const design = this.isJson(this.design) ? JSON.parse(this.design) : this.design;
      try {
        
        this.$refs['emailEditor'].editor.loadDesign(JSON.parse(design));

      } catch (e) {
        console.error('editorLoaded error', e);
      }
    },

    async export_pdf() {
      try {
        const pdfData = await new Promise((resolve, reject) => {
          this.$refs['emailEditor'].editor.exportPdf(function(data) {
            var json = data.design; // design json
            var pdfUrl = data.url; // pdf url

            // Do something with the PDF url

            resolve({ json, pdfUrl }); // Resolve the promise with the desired result
          }, reject); // Reject the promise if there's an error in the exportPdf function
        });

        // Use the pdfData object if needed
        console.log(pdfData.json);
        console.log(pdfData.pdfUrl);

        return pdfData; // Return the pdfData object
      } catch (err) {
        // Handle any errors
        console.error('pdf export error', err);
        throw err; // Optional: rethrow the error
      }
    },


    exportPlaintext() {
      return new Promise(async (resolve, reject) => {
        var that = this;

        this.$refs['emailEditor'].editor.exportPlainText(function (data) {
          var text = data.text;

          resolve(text);
        });
      });

    },
    exportHTML() {
      return new Promise(async (resolve, reject) => {
        this.$refs['emailEditor'].editor.saveDesign(
          (data) => {
            this.$refs['emailEditor'].editor.exportHtml(
              (val) => {
                resolve({
                  edit_html: data,
                  html: val.html
                });
              }
            )
          }
        );
      });

    },
    saveDesign() {
      var that = this;

      this.$refs['emailEditor'].editor.exportPlainText(function (data) {
        var text = data.text;

        that.$emit('plaintext', text);
      });

      this.$refs['emailEditor'].editor.saveDesign(
        (data) => {
          this.$refs['emailEditor'].editor.exportHtml(
            (val) => {
              this.$emit('saveDesign', JSON.stringify(data), val.html);
            }
          )
        }
      );
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch {
        return false;
      }
      return true;
    }
  },
}
</script>
<style scoped>
.unlayer-editor {
  height: 80vh;
}

#app .container {
  background-color: #293039;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

#bar {
  flex: 1;
  background-color: #293039;
  color: white;
  padding: 10px;
  display: flex;
  max-height: 65px;
}

#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}

#bar button {
  min-width: 80px;
  cursor: pointer;
}
</style>
