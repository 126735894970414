<template>
  <b-modal ref="instancesModal" hide-footer >
    <b-row>
      <b-col cols="12">
        <div class="d-block text-center">
          <h3>Välj instanser</h3>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <InstancesSelectableTable
          ref="instancesTable"
        />
      </b-col>
    </b-row>
    <b-row class="mt-8 mb-8">
      <b-col cols="6">
        <b-button class="" variant="primary" block @click="onConfirm">Bekräfta</b-button>
      </b-col>
      <b-col cols="6">
        <b-button class="" variant="outline-secondary" block @click="onCancel">Ångra</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<style lang="scss" scoped>
</style>
<script>

import InstancesSelectableTable from '@/view/pages/ml/ths/instances/InstancesSelectableTable.vue';


export default {
  name: 'selectInstancesModal',
  data() {
    return {};
  },
  components: {
    InstancesSelectableTable
  },
  emits: ['confirm', 'cancel'],
  async mounted() {},
  methods: {
    show() {
      this.$refs['instancesModal'].show();
    },
    onConfirm() {
      const selection = this.$refs['instancesTable'].getSelection();
      this.$refs['instancesModal'].hide();

      this.$emit('confirm', selection);
    },
    onCancel() {
      this.$refs['instancesModal'].hide();

      this.$emit('cancel');
    },

  },
  computed: {

  }
};
</script>
